import React from 'react';
import Footer from '../components/Footer';
import '../style/BlogPage.css';
import Header from '../components/Header';

const BlogPage = () => {
    return (
        <div className="_blogLibrary">
            <Header />
            Under maintenance
            <Footer href="/home" />
        </div>
    );
}
export default BlogPage;